import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Market from './pages/Market';
import Team from './pages/Team';
import Assets from './pages/Assets';
import My from './pages/My';
import BannerSlider from './components/BannerSlider';
import AnnouncementTicker from './components/AnnouncementTicker';
import IconsSection from './components/IconsSection';
import PromotionsSection from './components/PromotionsSection';
import MarketSection from './components/MarketSection';

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          {/* Define Home route to load the default components */}
          <Route path="/" element={
            <>
              {/* Banner/Slider Section */}
              <BannerSlider />

              {/* Announcement Ticker Section */}
              <AnnouncementTicker />

              {/* Icons Section */}
              <IconsSection />

              {/* Promotion Images Section */}
              <PromotionsSection />

              {/* Market Section */}
              <MarketSection />

            </>
          } />
          <Route path="/market" element={<Market />} />
          <Route path="/team" element={<Team />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/my" element={<My />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
