import React, { useState } from 'react';
import { Box, Grid, Typography, AppBar, Tabs, Tab } from '@mui/material';

const Team = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#121212', color: '#ffffff', p: 2 }}>
      {/* Static Team Information */}
      <Grid container spacing={2} sx={{ mb: 2, backgroundColor: '#1e1e1e', borderRadius: '8px', p: 2 }}>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Team Size</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>0</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Recharge Members</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>0</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Team Recharge</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>0</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Team Size Today</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>0</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Effective Size Today</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>0</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Team Withdraw</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>0</Typography>
        </Grid>
        <Grid item xs={6} textAlign="center" sx={{ backgroundColor: '#2c2c2c', p: 1, borderRadius: '8px' }}>
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Salary Today</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#f59e0b' }}>85.52</Typography>
        </Grid>
        <Grid item xs={6} textAlign="center" sx={{ backgroundColor: '#2c2c2c', p: 1, borderRadius: '8px' }}>
          <Typography variant="subtitle2" sx={{ color: '#9e9e9e' }}>Salary Total</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#f59e0b' }}>795.85</Typography>
        </Grid>
      </Grid>

      {/* Tabs for Levels */}
      <Box sx={{ width: '100%', mt: 2 }}>
        <AppBar position="static" sx={{ backgroundColor: '#1e1e1e' }}>
          <Tabs value={tabIndex} onChange={handleChange} variant="fullWidth" textColor="inherit" indicatorColor="primary">
            <Tab label="Level 1" />
            <Tab label="Level 2" />
            <Tab label="Level 3" />
          </Tabs>
        </AppBar>
        <TabPanel value={tabIndex} index={0}>
          {/* Level 1 Content */}
          <Typography sx={{ color: '#ffffff', p: 2 }}>Clients: 0 | Recharged: 0</Typography>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {/* Level 2 Content */}
          <Typography sx={{ color: '#ffffff', p: 2 }}>Clients: 0 | Recharged: 0</Typography>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          {/* Level 3 Content */}
          <Typography sx={{ color: '#ffffff', p: 2 }}>Clients: 0 | Recharged: 0</Typography>
        </TabPanel>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default Team;
