import React from 'react';
import { Grid, Typography } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import DownloadIcon from '@mui/icons-material/Download';

const customColor = 'rgb(20, 83, 45)'; // Darker custom color

const IconsSection = () => {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={3} textAlign="center">
        <MonetizationOnIcon sx={{ color: customColor }} fontSize="large" />
        <Typography variant="subtitle1" sx={{ color: customColor, fontWeight: 'bold' }}>
          Recharge
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <BarChartIcon sx={{ color: customColor }} fontSize="large" />
        <Typography variant="subtitle1" sx={{ color: customColor, fontWeight: 'bold' }}>
          Withdraw
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <HeadsetMicIcon sx={{ color: customColor }} fontSize="large" />
        <Typography variant="subtitle1" sx={{ color: customColor, fontWeight: 'bold' }}>
          Service
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <DownloadIcon sx={{ color: customColor }} fontSize="large" />
        <Typography variant="subtitle1" sx={{ color: customColor, fontWeight: 'bold' }}>
          APP
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IconsSection;
