import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet'; // Update with the correct wallet icon
import '../App.css';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const customColor = 'rgb(20, 83, 45)'; // Darker custom color
  const [value, setValue] = useState(location.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#121212' }}> {/* Dark background */}
      {/* Top App Bar */}
      <AppBar position="static" sx={{ backgroundColor: '#000' }}> {/* Black app bar */}
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#e91e63' }}> {/* Pinkish red color for the logo text */}
            Guccn
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Box sx={{ p: 1 }}>
        {children} {/* This will render the children passed to Layout */}
      </Box>

      {/* Bottom Navigation */}
      <BottomNavigation value={value} onChange={handleChange} showLabels sx={{ backgroundColor: '#000' }}>
        <BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} sx={{ color: customColor }} />
        <BottomNavigationAction label="Market" value="/market" icon={<BarChartIcon />} sx={{ color: customColor }} />
        <BottomNavigationAction label="Team" value="/team" icon={<PeopleIcon />} sx={{ color: customColor }} />
        <BottomNavigationAction label="Assets" value="/assets" icon={<WalletIcon />} sx={{ color: customColor }} />
        <BottomNavigationAction label="My" value="/my" icon={<AccountCircleIcon />} sx={{ color: customColor }} />
      </BottomNavigation>
    </Box>
  );
};

export default Layout;
