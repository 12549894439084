import React from 'react';
import { Grid, Box } from '@mui/material';

const PromotionsSection = () => {
  return (
    <Box sx={{ mt: 3 }}> {/* Add margin-top to space out from the icons */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <img src={`${process.env.PUBLIC_URL}/promo1.png`} alt="Promotion Offer 1" style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={`${process.env.PUBLIC_URL}/promo2.jpg`} alt="Promotion Offer 2" style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromotionsSection;
