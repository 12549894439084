import React from 'react';
import { VolumeUp } from '@mui/icons-material'; // Example icon from Material-UI

const AnnouncementTicker = () => {
  return (
    <div style={tickerContainerStyle}>
      <VolumeUp style={{ marginRight: '10px', color: '#fff' }} /> {/* Static icon */}
      <div style={tickerContentStyle}>
        Welcome to the Guccn official trading platform. Earn up to 8% daily with our exclusive trading offers!
      </div>
    </div>
  );
};

const tickerContainerStyle = {
  display: 'flex', // Allows icon and text to be on the same line
  alignItems: 'center', // Vertically centers content
  width: '100%',
  overflow: 'hidden',
  backgroundColor: '#000', // Background color of the ticker
  color: '#fff', // Text color
  position: 'relative',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  padding: '10px 0',
};

const tickerContentStyle = {
  display: 'inline-block',
  animation: 'ticker 20s linear infinite', // Slower animation (increase duration for slower speed)
};

export default AnnouncementTicker;
