import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Typography, Box } from '@mui/material';

const MarketSection = () => {
  const [marketData, setMarketData] = useState([]);

  const logoUrls = {
    BTCUSDT: '/BTC.png',
    ETHUSDT: '/ETH.png',
    DOGEUSDT: '/DOGE.png',
    TRXUSDT: '/TRX.png',
    XRPUSDT: '/XRP.png',
    EOSUSDT: '/EOS.png',
    LTCUSDT: '/LTC.png',
    FILUSDT: '/FIL.png',
    ADAUSDT: '/ADA.png',
    BCHUSDT: '/BCH.png',
  };

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await axios.get(
          'https://data-api.binance.vision/api/v3/ticker/24hr?symbols=["BTCUSDT","ETHUSDT","DOGEUSDT","TRXUSDT","XRPUSDT","EOSUSDT","LTCUSDT","FILUSDT","ADAUSDT","BCHUSDT"]'
        );
        setMarketData(response.data);
      } catch (error) {
        console.error('Error fetching market data:', error);
      }
    };

    fetchMarketData();

    const interval = setInterval(fetchMarketData, 1000);

    return () => clearInterval(interval);
  }, []);

  // Helper function to format volume
  const formatVolume = (volume) => {
    if (volume >= 1000000) {
      return `${(volume / 1000000).toFixed(2)}M`; // Display in millions (M)
    } else if (volume >= 1000) {
      return `${(volume / 1000).toFixed(2)}K`; // Display in thousands (K)
    }
    return volume.toString(); // Display as is if less than 1000
  };

  return (
    <Box sx={{ p: 1, pb: 8, backgroundColor: '#121212' }}>
      <Typography variant="h6" sx={{ color: '#ffffff', mb: 2 }}>
        Market
      </Typography>
      <Grid container spacing={2}>
        {marketData.map((data) => (
          <Grid item xs={12} key={data.symbol}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#1e1e1e',
                p: 2,
                borderRadius: '8px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={logoUrls[data.symbol]}
                  alt={`${data.symbol} logo`}
                  style={{ width: '48px', height: '48px', marginRight: '16px' }} // Increased size to 48x48 pixels
                />
                <Box>
                  <Typography sx={{ color: '#d1d5db' }}>
                    {data.symbol.replace('USDT', '/USDT')}
                  </Typography>
                  <Typography sx={{ color: '#a3a3a3' }}>Vol: {formatVolume(parseFloat(data.volume))}</Typography> {/* Display formatted Volume */}
                </Box>
              </Box>
              <Typography
  sx={{ 
    color: data.priceChangePercent > 0 ? '#22c55e' : '#ef4444',
    fontWeight: 'bold'  // Add this line to make the text bold
  }}
>
                {parseFloat(data.lastPrice).toFixed(2)}
              </Typography>
              <Typography
                sx={{
                  color: data.priceChangePercent > 0 ? '#d1d5db' : '#d1d5db',
                  backgroundColor: data.priceChangePercent > 0 ? '#22c55e' : '#ef4444',
                  p: '4px 8px',
                  borderRadius: '4px',
                }}
              >
                {parseFloat(data.priceChangePercent).toFixed(2)}%
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MarketSection;
