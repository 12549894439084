import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../App.css'; // Import the CSS file where the styles are written

const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    customPaging: (i) => (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: '#ddd',
        }}
      />
    ),
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <ul style={{ margin: 0, padding: 0, listStyleType: 'none', display: 'flex' }}>
          {dots.map((dot, index) => (
            <li
              key={index}
              style={{
                margin: '0 5px',
                cursor: 'pointer',
                display: 'inline-block',
              }}
            >
              {React.cloneElement(dot.props.children, {
                style: {
                  ...dot.props.children.props.style,
                  backgroundColor: dot.props.className.includes('slick-active') ? '#ff0000' : '#ddd',
                },
              })}
            </li>
          ))}
        </ul>
      </div>
    ),
  };

  const banners = [
    '/banner1.png',
    '/banner2.jpg',
    '/banner3.jpg',
    '/banner4.jpg',
    '/banner5.png',
  ];

  return (
    <div className="banner-slider-container">
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <div key={index} style={{ width: '100%', textAlign: 'center' }}>
            <img
              className="banner-slider-image"
              src={banner}
              alt={`Banner ${index + 1}`}
              onError={(e) => console.error(`Error loading image ${banner}:`, e.target)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerSlider;
