import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Typography, Box } from '@mui/material';

const Market = () => {
  const [marketData, setMarketData] = useState([]);

  const logoUrls = {
    BTCUSDT: '/BTC.png',
    ETHUSDT: '/ETH.png',
    DOGEUSDT: '/DOGE.png',
    TRXUSDT: '/TRX.png',
    XRPUSDT: '/XRP.png',
    EOSUSDT: '/EOS.png',
    LTCUSDT: '/LTC.png',
    FILUSDT: '/FIL.png',
    ADAUSDT: '/ADA.png',
    BCHUSDT: '/BCH.png',
    BNBUSDT: '/BNB.png',
    CRVUSDT: '/CRV.png',
    SOLUSDT: '/SOL.png',
    MATICUSDT: '/MATIC.png',
    DOTUSDT: '/DOT.png',
    WBTCUSDT: '/WBTC.png',
    DAIUSDT: '/DAI.png',
    AVAXUSDT: '/AVAX.png',
    LINKUSDT: '/LINK.png',
    ATOMUSDT: '/ATOM.png',
    UNIUSDT: '/UNI.png',
    XMRUSDT: '/XMR.png',
    ETCUSDT: '/ETC.png',
    XLMUSDT: '/XLM.png',
    ICPUSDT: '/ICP.png',
    LDOUSDT: '/LDO.png',
    HBARUSDT: '/HBAR.png',
    APTUSDT: '/APT.png',
    ARBUSDT: '/ARB.png',
    VETUSDT: '/VET.jpg',
    NEARUSDT: '/NEAR.png',
    QNTUSDT: '/QNT.png',
    AAVEUSDT: '/AAVE.png',
    GRTUSDT: '/GRT.png',
    STXUSDT: '/STX.png',
    EGLDUSDT: '/EGLD.png',
    MKRUSDT: '/MKR.png',
    OPUSDT: '/OP.png',
    ALGOUSDT: '/ALGO.jpg',
    XTZUSDT: '/XTZ.png',
    FTMUSDT: '/FTM.png',
    SANDUSDT: '/SAND.png',
    THETAUSDT: '/THETA.png',
    MANAUSDT: '/MANA.png',
    AXSUSDT: '/AXS.png',
    RNDRUSDT: '/RNDR.png',
    IMXUSDT: '/IMX.png',
    RPLUSDT: '/RPL.png',
    APEUSDT: '/APE.png',
  };

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await axios.get(
          'https://data-api.binance.vision/api/v3/ticker/24hr?symbols=["BTCUSDT","ETHUSDT","DOGEUSDT","TRXUSDT","XRPUSDT","EOSUSDT","LTCUSDT","FILUSDT","ADAUSDT","BCHUSDT","BNBUSDT","CRVUSDT","SOLUSDT","MATICUSDT","DOTUSDT","WBTCUSDT","DAIUSDT","AVAXUSDT","LINKUSDT","ATOMUSDT","UNIUSDT","XMRUSDT","ETCUSDT","XLMUSDT","ICPUSDT","LDOUSDT","HBARUSDT","APTUSDT","ARBUSDT","VETUSDT","NEARUSDT","QNTUSDT","AAVEUSDT","GRTUSDT","STXUSDT","EGLDUSDT","MKRUSDT","OPUSDT","ALGOUSDT","XTZUSDT","FTMUSDT","SANDUSDT","THETAUSDT","MANAUSDT","AXSUSDT","RNDRUSDT","IMXUSDT","RPLUSDT","APEUSDT"]'
        );
        setMarketData(response.data);
      } catch (error) {
        console.error('Error fetching market data:', error);
      }
    };

    fetchMarketData();

    const interval = setInterval(fetchMarketData, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatVolume = (volume) => {
    if (volume >= 1000000) {
      return `${(volume / 1000000).toFixed(2)}M`;
    } else if (volume >= 1000) {
      return `${(volume / 1000).toFixed(2)}K`;
    }
    return volume.toString();
  };

  return (
    <Box sx={{ p: 1, pb: 8, backgroundColor: '#121212' }}>
      <Typography variant="h6" sx={{ color: '#ffffff', mb: 2 }}>
        Market
      </Typography>
      <Grid container spacing={2}>
        {marketData.map((data) => (
          <Grid item xs={12} key={data.symbol}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#1e1e1e',
                p: 2,
                borderRadius: '8px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={logoUrls[data.symbol]}
                  alt={`${data.symbol} logo`}
                  style={{ width: '48px', height: '48px', marginRight: '16px' }}
                />
                <Box>
                  <Typography sx={{ color: '#ffffff' }}>
                    {data.symbol.replace('USDT', '/USDT')}
                  </Typography>
                  <Typography sx={{ color: '#9e9e9e' }}>Vol: {formatVolume(parseFloat(data.volume))}</Typography>
                </Box>
              </Box>
              <Typography
                sx={{ 
                  color: data.priceChangePercent > 0 ? '#22c55e' : '#ef4444',
                  fontWeight: 'bold'
                }}
              >
                {parseFloat(data.lastPrice).toFixed(2)}
              </Typography>
              <Typography
                sx={{
                  color: data.priceChangePercent > 0 ? '#22c55e' : '#ef4444',
                  backgroundColor: data.priceChangePercent > 0 ? '#14532d' : '#7f1d1d',
                  p: '4px 8px',
                  borderRadius: '4px',
                }}
              >
                {parseFloat(data.priceChangePercent).toFixed(2)}%
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Market;
